// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beitraege-js": () => import("./../../../src/pages/beitraege.js" /* webpackChunkName: "component---src-pages-beitraege-js" */),
  "component---src-pages-beitraege-reihen-js": () => import("./../../../src/pages/beitraege/reihen.js" /* webpackChunkName: "component---src-pages-beitraege-reihen-js" */),
  "component---src-pages-beitraege-vorschau-js": () => import("./../../../src/pages/beitraege/vorschau.js" /* webpackChunkName: "component---src-pages-beitraege-vorschau-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-notizen-js": () => import("./../../../src/pages/notizen.js" /* webpackChunkName: "component---src-pages-notizen-js" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

