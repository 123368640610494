module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mv-Blog","short_name":"MV","start_url":"/","background_color":"#33ccff","theme_color":"#33ccff","display":"minimal-ui","icon":"src/images/mv.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"94cb676dc4a30afe9d64d51614327454"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
