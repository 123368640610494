import "./src/styles/style.css";

import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby-link";

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/', {replace: true})
}

export const wrapRootElement = ({element}) => {
    return(
  <Auth0Provider
    domain="dev-89b5i1i1.us.auth0.com"
    clientId="SewUAKz0rNhheDEAtemRol0MWm5FMy5O"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens
    cacheLocation="localstorage"
  >
    {element}
  </Auth0Provider>)
}